import React from 'react';
import { FooterContainer } from '../../styledComponents/FooterContainer';
import { FooterText } from '../../styledComponents/FooterText';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>© 2024 | <a href="https://storycloner.com">Snapchat Story Downloader</a> | We do not own any content |<a href="https://storycloner.com/about"> About us</a></FooterText>
    </FooterContainer>
  );
};

export default Footer;

